@import url("https://fonts.googleapis.com/css?family=Quicksand:300,500");
@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css");

html,
body {
  height: 100%;
  position: relative;
}

.intro {
  display: inline-flex;
  width: auto;
  height: auto;
  margin: 0 auto;
  /* padding-top: 25%; */
}
.intro h1 {
  z-index: 0;
  font-family: "Quicksand", sans-serif;
  font-size: 5vw;
  color: #616161;
  overflow: hidden; /* Ensures the content is not revealed until the animation */
  border-right: 0.15em solid #14b8af; /* The typwriter cursor */
  white-space: nowrap; /* Keeps the content on a single line */
  margin: 0 auto; /* Gives that scrolling effect as the typing happens */
  letter-spacing: 0.15em; /* Adjust as needed */
  animation: typing 3.5s steps(40, end), blink-caret 0.75s step-end infinite;
}

.words {
  font-family: "Quicksand", sans-serif;
  font-size: 6vmin;
  color: white;
  word-wrap: break-word;
  width: 600px;
  min-width: 45vw;
  display: flex;
  justify-content: center;
  align-self: center;
  align-content: center;
  padding: 3vw;
}

.wordColorSize {
  font-family: "Quicksand", sans-serif;
  font-size: 7vmin;
  color: white;
  width: 47vmax;
}

.landing {
  align-items: center;
  justify-content: space-around;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  min-height: 85vh;
  width: 100vw;
}

.headshot {
  float: left;
  padding: 50px;
}
.headshot img {
  border-radius: 3vw;
  border: 4px solid white;
  box-shadow: 0 4px 16px #616161;
}

.view-resume {
  background-color: #616161;
  font-family: "Quicksand", sans-serif;
  border: none;
  border-radius: 8px;
  color: white;
  padding: 10px 15px 10px 15px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 30px;
  margin: 15px 2px 30px;
  cursor: pointer;
  border: 1px solid #14b8af;
  background-image: linear-gradient(#7e7e7e, #616161, #7e7e7e);
}

.skills-section {
  width: 390px;
  text-align: center;
  padding: 30px;
}

.skills-section p {
  color: #616161;
  font-size: 17px;
}

.skills-section h1 {
  color: #14b8af;
}

.skill-flag {
  border: solid 1px #14b8af;
  color: #14b8af;
  margin: 5px;
  padding: 5px;
  border-radius: 10px;
  text-align: center;
  font-weight: 550;
  max-width: 150px;
}

.flag-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  font-size: 20px;
  justify-content: center;
}
.skills-container {
  display: flex;
  align-content: center;
  flex-flow: row wrap;
  justify-content: center;
  width: 100vw;
}

.homeSection {
  display: flex;
  align-content: space-evenly;
  align-items: center;
  justify-content: space-around;
  flex-flow: row wrap;
  width: 100vw;
  min-height: 100vh;
  font-family: "Quicksand", sans-serif;
}

.homeSection.color {
  background-color: #14b8af;
}

.homeSection.bg {
  background-color: white;
  overflow: hidden;
  display: inline-block;
  padding: 10px;
  font-weight: bold;
  color: #14b8af;
  font-family: "Quicksand", sans-serif;
}
.homeSection.bg-text {
  font-size: 9.5vw;
  color: #14b8af;
  background-image: url(../images/t2.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  display: inline-block;
  vertical-align: middle;
  line-height: 22vh;
}

.rotateLeft {
  transform: rotate(5deg);
}

.rotateRight {
  transform: rotate(-5deg);
}

.fade-in-section {
  opacity: 0;
  transform: translateY(20vh);
  visibility: hidden;
  transition: opacity 0.6s ease-out, transform 1.2s ease-out;
  will-change: opacity, visibility;
}
.fade-in-section.is-visible {
  opacity: 1;
  visibility: visible;
  transform: none;
}
.footer {
  position: relative;
  bottom: 0;
  width: 100%;
  background-color: #14b8af;
  font-size: 5em;
  bottom: 0;
  height: 30vh;
  font-family: "Quicksand", sans-serif;
}

i {
  color: white;
  padding: 3vw;
}

i.fas {
  color: white;
}

i.fas.fa-facebook-official {
  color: white;
}

i.fas.fa-github {
  color: white;
}
i.fas.fa-linkedin {
  color: white;
}

@keyframes movingTopToBottom {
  0% {
    top: -500px;
  }
  100% {
    top: 5px;
    opacity: 100;
  }
}

/* The typing effect */
@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

/* The typewriter cursor effect */
@keyframes blink-caret {
  from,
  to {
    border-color: transparent;
  }
  50% {
    border-color: #14b8af;
  }
}
