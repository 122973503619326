@import url("https://fonts.googleapis.com/css?family=Quicksand:300,500");

#nav-container {
  width: 100%;
  background: transparent;
  float: right;
  z-index: 1;
}
nav {
  height: 30px;
  padding: 0;
  margin: 0;
}
nav ul {
  top: 0;
  float: right;
  list-style-type: none;
  margin: 0;
  text-align: center;
  display: flex;
  flex-flow: row wrap;
  align-content: flex-start;
  justify-content: center;
  align-items: center;
  background: transparent;
  transition: top 0.3s;
}

nav li {
  float: top;
  margin: 25px;
  padding-right: 20px;
}
nav li a {
  display: inline-block;
  color: #14b8af;
  font-family: "Quicksand", sans-serif;
  font-weight: 1000;
  margin: 10%;
  padding: 10px;
  font-size: 20px;
  text-decoration: none;
  text-align: center;
  align-content: center;
}
nav li a:hover {
  animation: bounce 2.5s infinite;
  -webkit-animation: bounce 2.5s infinite;
  -moz-animation: bounce 2.5s infinite;
  -o-animation: bounce 2.5s infinite;
}

@-webkit-keyframes bounce {
  0%,
  100% {
    -webkit-transform: translateY(0);
  }
  50% {
    -webkit-transform: translateY(-10px);
  }
}
@-moz-keyframes bounce {
  0%,
  100% {
    -moz-transform: translateY(0);
  }
  50% {
    -moz-transform: translateY(-10px);
  }
}
@-o-keyframes bounce {
  0%,
  100% {
    -o-transform: translateY(0);
  }
  50% {
    -o-transform: translateY(-10px);
  }
}
@keyframes bounce {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}
